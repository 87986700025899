<template>
  <div class="campaign">
    <!-- add dialog for campaign -->
    <v-snackbar v-model="snackbar" :timeout="4000" top>
      {{ snackMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-dialog v-model="deleteDialog" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Confirmation
        </v-card-title>

        <v-card-text>
          <br />
          Are you sure want to delete <b>{{ delCampaign.name }}</b> form
          campaign list?
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="doDelCampaign()"> Yes </v-btn>
          <v-btn color="primary" text @click="deleteDialog = false"> No </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="addDialog" max-width="80%">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Add Campaign
          <!-- {{ addCampaign.name }} -->
        </v-card-title>

        <v-card-actions>
          <v-container style="padding: 30px">
            <v-form style="width: 100%" ref="form" lazy-validation>
              <v-row>
                <v-col>
                  <v-row>
                    <v-text-field
                      required
                      :rules="rules.name"
                      v-model="addCampaign.name"
                      label="Name*"
                    ></v-text-field>
                  </v-row>
                  <v-row> </v-row>
                  <v-row>
                    <!-- from date  -->
                    <v-col class="py-0">
                      <v-datetime-picker
                        :rules="rules.campaignStartTime"
                        label="From*"
                        class="from-date"
                        v-model="addCampaign.campaignStartTime"
                        :max="addCampaign.campaignEndTime"
                      >
                        <template slot="dateIcon">
                          <v-icon>mdi-calendar</v-icon>
                        </template>
                        <template slot="timeIcon">
                          <v-icon>mdi-timer</v-icon>
                        </template>
                      </v-datetime-picker>
                      <p
                        v-if="!addCampaign.campaignStartTime && fromErrorMsg"
                        class="v-messages__message"
                        style="margin-top: -16px"
                      >
                        From required
                      </p>
                    </v-col>
                    <!-- to date -->
                    <v-col class="py-0">
                      <v-datetime-picker
                        required
                        :rules="rules.campaignEndTime"
                        label="To*"
                        v-model="addCampaign.campaignEndTime"
                        :max="addCampaign.campaignStartTime"
                        v-on:click="toErrorMsg = true"
                      >
                        <template slot="dateIcon">
                          <v-icon>mdi-calendar</v-icon>
                        </template>
                        <template slot="timeIcon">
                          <v-icon>mdi-timer</v-icon>
                        </template>
                      </v-datetime-picker>
                      <p
                        v-if="!addCampaign.campaignEndTime && toErrorMsg"
                        class="v-messages__message"
                        style="margin-top: -16px"
                      >
                        To required
                      </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-text-field
                      required
                      :rules="rules.location"
                      v-model="addCampaign.location"
                      label="Location/Address*"
                    ></v-text-field>
                  </v-row>
                  <v-row>
                    <v-textarea
                      v-model="addCampaign.description"
                      label="Description"
                      :rows="1"
                      auto-grow
                    />
                  </v-row>
                  <v-row>
                    <v-textarea
                      v-model="addCampaign.url"
                      label="URL"
                      :rows="1"
                      auto-grow
                    />
                  </v-row>
                  <v-row>
                    <v-file-input
                      label="Image*"
                      required
                      :rules="rules.img"
                      v-model="addImage"
                      clearable
                      accept="image/jpg,image/jpeg,image/png,image/gif"
                    ></v-file-input>
                  </v-row>
                </v-col>
              </v-row>

              <v-row>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="doAddCampaign()"
                  >Submit</v-btn
                >
              </v-row>
            </v-form>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- edit dialog for campaign -->
    <v-dialog v-model="editDialog" max-width="80%">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Edit Campaign
          <!-- {{ editCampaign.name }} -->
        </v-card-title>

        <v-card-actions>
          <v-container style="padding: 30px">
            <v-form style="width: 100%" ref="form" lazy-validation>
              <v-row>
                <v-col>
                  <v-row>
                    <v-text-field
                      required
                      :rules="rules.name"
                      v-model="editCampaign.name"
                      label="Name*"
                    ></v-text-field>
                  </v-row>
                  <v-row>
                    <!-- from date  -->
                    <v-col class="py-0">
                      <v-datetime-picker
                        required
                        :rules="rules.campaignStartTime"
                        label="From*"
                        v-model="editCampaign.campaignStartTime"
                      >
                        <template slot="dateIcon">
                          <v-icon>mdi-calendar</v-icon>
                        </template>
                        <template slot="timeIcon">
                          <v-icon>mdi-timer</v-icon>
                        </template>
                      </v-datetime-picker>
                      <p
                        v-if="!editCampaign.campaignEndTime && fromErrorMsg"
                        class="v-messages__message"
                        style="margin-top: -16px"
                      >
                        From required
                      </p>
                    </v-col>
                    <!-- to date -->
                    <v-col class="py-0">
                      <v-datetime-picker
                        required
                        :rules="rules.campaignEndTime"
                        label="From*"
                        v-model="editCampaign.campaignEndTime"
                      >
                        <template slot="dateIcon">
                          <v-icon>mdi-calendar</v-icon>
                        </template>
                        <template slot="timeIcon">
                          <v-icon>mdi-timer</v-icon>
                        </template>
                      </v-datetime-picker>
                      <p
                        v-if="!editCampaign.campaignEndTime && toErrorMsg"
                        class="v-messages__message"
                        style="margin-top: -16px"
                      >
                        To required
                      </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-text-field
                      required
                      :rules="rules.location"
                      v-model="editCampaign.location"
                      label="Location/Address*"
                    ></v-text-field>
                  </v-row>
                  <v-row>
                    <v-textarea
                      v-model="editCampaign.description"
                      label="Description"
                      :rows="1"
                      auto-grow
                    />
                  </v-row>
                  <v-row>
                    <v-textarea
                      v-model="editCampaign.url"
                      label="URL"
                      :rows="1"
                      auto-grow
                    />
                  </v-row>
                  <v-row>
                    <v-col style="display: flex; flex-flow: column-reverse">
                      <v-card
                        style="width: 20%"
                        v-if="image && image.endsWith('.png')"
                      >
                        <img :src="image" style="width: 100%" />
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn icon @click="deleteImage()">
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                      <p
                        class="no-img"
                        v-if="!image || (image && !image.endsWith('.png'))"
                      >
                        No image has been uploaded
                      </p>
                      <v-file-input
                        label="Image*"
                        required
                        :rules="rules.img"
                        v-model="editImage"
                        clearable
                        accept="image/jpg,image/jpeg,image/png,image/gif"
                      ></v-file-input>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="doEditCampaign()"
                  >Submit</v-btn
                >
              </v-row>
            </v-form>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- filter search -->
    <v-row>
      <v-col cols="12" sm="6" md="3">
        <v-text-field
          @keyup.enter.native="searchCampaign()"
          v-model="campaignSearch"
          label="ʕ•́ᴥ•̀ʔっ♡¯\_(ツ)_/¯"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-btn
          @click="searchCampaign()"
          @keyup.enter.native="searchCampaign()"
          large
          elevation
          color="primary"
        >
          Search
        </v-btn>
      </v-col>
      <v-btn fab color="red accent-2" @click="addDialog = true">
        <v-icon color="#fff">mdi-plus</v-icon>
      </v-btn>
    </v-row>
    <!-- campaign data listing table -->
    <v-data-table
      :headers="headers"
      :items="campaignList"
      :page.sync="pagination.page"
      :items-per-page="pagination.limit"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      @update:options="sortItems"
      hide-default-footer
      class="elevation-1"
    >
      <template v-slot:item="row">
        <tr>
          <td>{{ row.item.id }}</td>
          <td>{{ row.item.name }}</td>
          <td>{{ convertDateTime(row.item.campaignStartTime) }}</td>
          <td>{{ convertDateTime(row.item.campaignEndTime) }}</td>
          <td>
            <v-btn
              class="mx-2"
              fab
              dark
              small
              color="primary"
              @click="editButtonClick(row.item)"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </td>
          <td>
            <v-btn
              class="mx-2"
              fab
              dark
              small
              color="red"
              @click="delButtonClick(row.item)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-pagination
      v-model="pagination.page"
      :circle="true"
      :disabled="false"
      :length="pagination.noOfPage"
      :page="pagination.page"
      update:page
      :total-visible="10"
      @input="paginationChangeHandler"
    ></v-pagination>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

// import uploadImage from "../../../services/image-uploader";
import getAxios from "../../../services/axios-get";
import putAxios from "../../../services/axios-put";
import postAxios from "../../../services/axios-post";
import deleteAxios from "../../../services/axios-delete";
import uploadSignedImage from "../../../services/aws-upload-image";
import DatetimePicker from "vuetify-datetime-picker";
import moment from "moment";
Vue.use(VueAxios, axios);
Vue.use(DatetimePicker);

export default {
  data() {
    return {
      isLoading: false,
      time: null,
      menu2: false,
      search: null,
      campaignSearch: "",
      addDialog: false,
      editDialog: false,
      deleteDialog: false,
      addStartDateModel: false,
      editStartDateModel: false,
      addToDateModel: false,
      editToDateModel: false,
      image: null,
      snackbar: false,
      snackMessage: null,
      pagination: {
        limit: 10,
        noOfPage: 1,
        page: 1,
      },
      headers: [
        { text: "Id", value: "id" },
        { text: "Name", value: "name" },
        { text: "Start Date", value: "campaignStartTime" },
        { text: "End Date", value: "campaignEndTime" },
        { text: "Edit", value: "edit", sortable: false },
        { text: "Delete", value: "delete", sortable: false },
      ],
      campaignList: [],
      addCampaign: {
        name: null,
        campaignStartTime: null,
        campaignEndTime: null,
        location: null,
        description: null,
        url: null,
        img: null,
      },
      editCampaign: {
        name: null,
        campaignStartTime: null,
        campaignEndTime: null,
        location: null,
        description: null,
        url: null,
        img: null,
      },
      addImage: null,
      editImage: null,
      rules: {
        name: [v => !!v || "Name required"],
        campaignStartTime: [v => !!v || "From required"],
        campaignEndTime: [v => !!v || "To required"],
        location: [v => !!v || "Location required"],
        img: [v => !!v || "File required"],
      },
      toErrorMsg: false,
      fromErrorMsg: false,
      delCampaign: {
        id: "",
        name: "",
      },
      sortValue: null,
      sortBy: null,
      sortDesc: null,
    };
  },
  mounted() {
    this.fetchCampaignList();
  },
  methods: {
    sortItems({ sortBy, sortDesc }) {
      let columnName = sortBy[0];
      if (columnName && sortDesc[0] === true) {
        this.sortValue = columnName + ",desc";
      } else if (columnName && sortDesc[0] === false) {
        this.sortValue = columnName;
      } else {
        this.sortValue = null;
      }
      this.fetchCampaignList();
    },
    convertDateTime(date) {
      var parsedDate = moment
        .utc(date)
        .local()
        .format("YYYY-MM-DD HH:mm");
      return parsedDate;
    },
    searchCampaign() {
      this.fetchCampaignList();
    },
    deleteImage() {
      this.image = null;
      this.editCampaign.img = null;
      this.editImage = null;
    },
    fetchCampaignList() {
      const self = this;
      const params = {
        page: self.pagination.page - 1,
        size: self.pagination.limit,
        searchContent: self.campaignSearch,
        sort: self.sortValue,
      };
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/campaignSystem/campaignData`,
        params
      )
        .then(res => {
          if (res.data) {
            self.campaignList = res.data.data.content;
            self.pagination.noOfPage = res.data.data.totalPages;
          }
        })
        .catch(e => {
          console.log(e);
          this.snackbar = true;
          this.snackMessage = "Error!";
        });
    },
    paginationChangeHandler(pagination) {
      this.pagination.page = pagination;
      this.fetchCampaignList();
    },
    editButtonClick(item) {
      this.setEditItemInfo(item);
      this.editDialog = true;
    },
    setEditItemInfo(item) {
      this.editCampaign.id = item.id;
      this.editCampaign.name = item.name;
      this.editCampaign.campaignStartTime = item.campaignStartTime
        ? this.convertDateTime(item.campaignStartTime)
        : "";
      this.editCampaign.campaignEndTime = item.campaignEndTime
        ? this.convertDateTime(item.campaignEndTime)
        : "";
      this.editCampaign.location = item.location;
      this.editCampaign.description = item.description;
      this.editCampaign.url = item.url;
      const imgName = item.img
        ? item.img
            .split("https://ucars-bucket.s3-ap-southeast-1.amazonaws.com/")
            .slice(-1)[0]
        : null;
      this.editCampaign.img = imgName ? imgName : null;
      this.editImage = imgName ? imgName : null;
      this.image = item.img;
    },
    resetEditItemInfo() {
      this.editCampaign.id = null;
      this.editCampaign.name = null;
      this.editCampaign.campaignStartTime = null;
      this.editCampaign.campaignEndTime = null;
      this.editCampaign.location = null;
      this.editCampaign.description = null;
      this.editCampaign.url = null;
      this.editCampaign.img = null;
      this.image = null;
    },
    resetAddItemInfo() {
      this.addCampaign.id = null;
      this.addCampaign.name = null;
      this.addCampaign.campaignStartTime = null;
      this.addCampaign.campaignEndTime = null;
      this.addCampaign.location = null;
      this.addCampaign.description = null;
      this.addCampaign.url = null;
      this.addCampaign.img = null;
      this.image = null;
    },
    async doAddCampaign() {
      const self = this;
      try {
        this.fromErrorMsg = self.addCampaign.campaignStartTime ? false : true;
        this.toErrorMsg = self.addCampaign.campaignEndTime ? false : true;
        if (
          this.$refs.form.validate() &&
          !this.fromErrorMsg &&
          !this.toErrorMsg
        ) {
          if (self.addImage) {
            const uploadUrl = await getAxios(
              `${process.env.VUE_APP_SERVER_URL}/api/v2/file-storage/pre-signed-urls`,
              {
                format: "png",
                waterMark: false,
              },
              this.token
            );
            await uploadSignedImage(self.addImage, uploadUrl.data.originalUrl);
            self.addCampaign.img = uploadUrl.data.originalName;
          }
          postAxios(
            `${process.env.VUE_APP_SERVER_URL}/campaignSystem/addCampaign`,
            self.addCampaign
          )
            .then(res => {
              if (res.data.httpStatus === "OK") {
                self.addDialog = false;
                self.fetchCampaignList();
                this.resetAddItemInfo();
                this.$refs.form.reset();
                this.snackbar = true;
                this.snackMessage = "Campaign added successfully";
              }
            })
            .catch(e => {
              console.log(e);
              this.snackbar = true;
              this.snackMessage = "Error!";
            });
        }
      } catch (e) {
        console.error(e);
      }
    },
    async doEditCampaign() {
      const self = this;
      try {
        this.fromErrorMsg = self.editCampaign.campaignStartTime ? false : true;
        this.toErrorMsg = self.editCampaign.campaignEndTime ? false : true;
        if (
          this.$refs.form.validate() &&
          !this.fromErrorMsg &&
          !this.toErrorMsg
        ) {
          if (self.editImage && typeof self.editImage === "object") {
            const uploadUrl = await getAxios(
              `${process.env.VUE_APP_SERVER_URL}/api/v2/file-storage/pre-signed-urls`,
              {
                format: "png",
                waterMark: false,
              },
              this.token
            );
            await uploadSignedImage(self.editImage, uploadUrl.data.originalUrl);
            self.editCampaign.img = uploadUrl.data.originalName;
          }
          self.editCampaign.campaignStartTime = self.editCampaign
            .campaignStartTime
            ? new Date(self.editCampaign.campaignStartTime)
            : "";
          self.editCampaign.campaignEndTime = self.editCampaign.campaignEndTime
            ? new Date(self.editCampaign.campaignEndTime)
            : "";
          putAxios(
            `${process.env.VUE_APP_SERVER_URL}/campaignSystem/updCampaign`,
            self.editCampaign
          )
            .then(res => {
              if (res.data.httpStatus === "OK") {
                self.editDialog = false;
                self.fetchCampaignList();
                self.resetEditItemInfo();
                this.snackbar = true;
                this.snackMessage = "Campaign edit successfully";
              }
            })
            .catch(e => {
              console.log(e);
              this.snackbar = true;
              this.snackMessage = "Error!";
            });
        }
      } catch (e) {
        console.error(e);
      }
    },
    delButtonClick(item) {
      this.delCampaign.id = item.id;
      this.delCampaign.name = item.name;
      this.deleteDialog = true;
    },
    doDelCampaign() {
      const self = this;
      const param = {
        campaignId: self.delCampaign.id,
      };
      deleteAxios(
        `${process.env.VUE_APP_SERVER_URL}/campaignSystem/delCampaign`,
        param,
        self.token
      )
        .then(res => {
          if (res.data) {
            self.deleteDialog = false;
            self.fetchCampaignList();
            this.snackbar = true;
            this.snackMessage = "Campaign delete successfully";
          }
        })
        .catch(e => {
          console.log(e);
          this.snackbar = true;
          this.snackMessage = "Error!";
        });
    },
  },
};
</script>
<style>
.no-img {
  color: gray;
  font-size: small;
}
.v-application .error--text {
  color: rgba(0, 0, 0, 0.6) !important;
}
.v-messages__message {
  color: red !important;
  flex: 1 1 auto;
  font-size: 12px;
  min-height: 14px;
  min-width: 1px;
  position: relative;
}
/* .from-date > .theme--light.v-text-field > .v-input__control > .v-input__slot:before {
  border-color: rgb(255 0 0 / 42%) !important;
} */
</style>
